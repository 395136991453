import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import pic01 from '../assets/images/pic01.jpg'
import LearningPathsTools from '../components/LearningPathsTools'
import HeaderTwo from '../components/HeaderTwo'
import Layout from '../components/layout'
import Nav from '../components/Nav'


class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout>
        <Helmet title="Learning Paths - Connect the dots" />
        <LearningPathsTools />
      </Layout>
    )
  }
}

export default Index
